$(function () {

    function isset(v_var) {
        if (typeof (v_var) == 'number') {
            if (isNaN(v_var)) {
                return false;
            }
        }
        if (typeof (v_var) == 'undefined' || v_var === null) {
            return false;
        } else {
            return true;
        }
    };

    function empty(v_var) {
        if (!isset(v_var) || v_var === '') {
            return true;
        } else {
            return false;
        }
    };

    function check_error(mes) {
        if (isset(mes) && isset(mes[0])) {
            toastr.warning(mes[0]);
        }
    };

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    // показ модального окна заявки
    // $('#modal-form-order').on('show.bs.modal', function () {});
    $('#modal-form-order').on('shown.bs.modal', function (event) {
        // $(this).find('.no-ajax input[name=prop]').val(event.relatedTarget.getAttribute('data-bs-whatever'));
        // $(this).find('.ajax input[name=prop]').data('prop', event.relatedTarget.getAttribute('data-bs-whatever'));
        $(this).find('.no-ajax input[name=mark_2]').val(event.relatedTarget.getAttribute('data-bs-whatever'));
        $(this).find('.ajax input[name=mark_2]').data('prop', event.relatedTarget.getAttribute('data-bs-whatever'));

        // let el = $(this).find('input[type=text]').filter(':visible:first');
        let el = $(this).find('input:text:visible:first');
        setTimeout(function () {
            el.trigger('focus');
        }, 1000);
    });

    // отправка форм: контактов | заявок | отзывов
    $('.form-order .ajax, .form-contacts .ajax, .form-site_review .ajax, .form-subscribe .ajax, .form-comments .ajax').on('submit', function (event) {
        event.preventDefault();
        send_form($(this));
    });
    function send_form(form) {
        let f_filesUpload = false;

        /*
        > deprecated > pdata.formtype.type
        // let pdata = form.find('.form-prop').data('prop'); console.log(pdata);
        // let pdata = form.find('.form-prop').data(); console.log(pdata); console.log(pdata.prop); console.log(pdata.formtype.type);
        let pdata = form.find('input[name=prop]').data();
        if (!isset(pdata) || !isset(pdata.formtype) || !isset(pdata.formtype.type)) {
        */

        let form_type = form.find('input[name=form_type]').val();
        if (empty(form_type)) {
            toastr.error('Ошибка формы');
            $('#modal-form-order').modal('hide');
            $('#modal-form-site_review').modal('hide');
            return false
        } else {
            if (form_type == 'order' || form_type == 'contacts') {
                let mark_2_local = form.find('input[name=mark_2]').val();
                let pdata = form.find('input[name=mark_2]').data();
                if (isset(pdata) && isset(pdata.prop)) {
                    mark_2_local = pdata.prop;
                }
                var formData = {
                    form_type: form.find('input[name=form_type]').val(),
                    mark_1: form.find('input[name=mark_1]').val(),
                    mark_2: mark_2_local,
                    bmark: form.find('textarea[name=bmark]').val(),
                    name: form.find('input[name=name]').val(),
                    phone: form.find('input[name=phone]').val(),
                    email: form.find('input[name=email]').val(),
                    message: form.find('textarea[name=message]').val(),
                    // prop: pdata.prop,
                }
            }
            if (form_type == 'site_review') {
                // f_filesUpload = true;
                if (f_filesUpload) {
                    var formData = new FormData();
                    formData.append('bmark', form.find('textarea[name=bmark]').val());
                    formData.append('name', form.find('input[name=name]').val());
                    formData.append('email', form.find('input[name=email]').val());
                    formData.append('career', form.find('input[name=career]').val());
                    formData.append('message', form.find('textarea[name=message]').val());
                    let review_rank = form.find('input[name=review_rank]:checked').val();
                    if (isset(review_rank)) { // undefined error >> must be null
                        formData.append('review_rank', review_rank);
                    }
                    let img = form.find('input[name=img]')[0].files[0];
                    if (isset(img)) { // undefined error >> must be null
                        formData.append('img', img);
                        // console.log(img);
                    }
                } else {
                    var formData = {
                        bmark: form.find('textarea[name=bmark]').val(),
                        name: form.find('input[name=name]').val(),
                        email: form.find('input[name=email]').val(),
                        career: form.find('input[name=career]').val(),
                        message: form.find('textarea[name=message]').val(),
                        review_rank: form.find('input[name=review_rank]:checked').val(),
                    }
                }
            }
            if (form_type == 'subscribe') {
                let mark_2_local = form.find('input[name=mark_2]').val();
                let pdata = form.find('input[name=mark_2]').data();
                if (isset(pdata) && isset(pdata.prop)) {
                    mark_2_local = pdata.prop;
                }
                var formData = {
                    form_type: form.find('input[name=form_type]').val(),
                    mark_1: form.find('input[name=mark_1]').val(),
                    mark_2: mark_2_local,
                    bmark: form.find('textarea[name=bmark]').val(),
                    email: form.find('input[name=email]').val(),
                }
            }
            if (form_type == 'comments') {
                var formData = {
                    bmark: form.find('textarea[name=bmark]').val(),
                    article_id: form.find('input[name=article_id]').val(),
                    name: form.find('input[name=name]').val(),
                    text: form.find('textarea[name=text]').val(),
                }
            }
            // form.find('.error').remove();
            // if (form.find('input[name=unused_name]').val() === '') {
            //     form.find('input[name=unused_name]').before('<div class="error">Введите имя</div>');
            //     return false;
            // }
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            let ajaxObj = {
                // headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
                type: 'POST',
                url: '/form/' + form_type,
                dataType: 'json',
                data: formData,
                success: (data) => {
                    // console.log('success send form');
                    // console.log(data.request_data);
                    // console.log(Cookies.get('ses_cookie'));
                    form.find('textarea[name=bmark]').val('');
                    if (form_type == 'contacts') {
                        $('#modal-form-contacts').modal('hide');
                        form.find('input[name=name]').val('');
                        form.find('input[name=phone]').val('');
                        form.find('input[name=email]').val('');
                        form.find('textarea[name=message]').val('');
                    } else if (form_type == 'order') {
                        $('#modal-form-order').modal('hide');
                        form.find('input[name=name]').val('');
                        form.find('input[name=email]').val('');
                        form.find('input[name=phone]').val('');
                        form.find('input[name=message]').val('');
                    } else if (form_type == 'site_review') {
                        $('#modal-form-site_review').modal('hide');
                    } else if (form_type == 'subscribe') {
                        form.find('input[name=email]').val('');
                    } else if (form_type == 'comments') {
                        form.find('input[name=name]').val('');
                        form.find('textarea[name=text]').val('');
                    }
                    toastr.info(data.status_mes);
                },
                // error: function (data) {console.log(data);}
                error: (data) => {
                    console.log('error send form');
                    // console.log(data);
                    // console.log(data.responseJSON);
                    // console.log(data.request_data);
                    if (isset(data.responseJSON.errors)) {
                        // console.log(data.responseJSON.errors);
                        for (let key in data.responseJSON.errors) {
                            check_error(data.responseJSON.errors[key]);
                        }
                    }
                }
            }
            if (f_filesUpload) {
                ajaxObj.enctype = 'multipart/form-data';
                // ajaxObj.mimeType = 'multipart/form-data';
                ajaxObj.async = false;
                ajaxObj.cache = false;
                ajaxObj.processData = false;
                ajaxObj.contentType = false;
            }
            $.ajax(ajaxObj);
        }
    }
    // END отправка форм

    // показать форму комментария
    // $('.an-item .b-comments .col-info .quest .btn').on('click', function () {
    //     $(this).hide();
    //     $('.an-item .b-comments .b-form').show(500)
    // });

});
